import * as React from "react";
import { classNames } from "@plinknz/tah-website-elements";

type Variant = "primary" | "secondary" | "tertiary";
type Size = "large" | "small";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
    variant?: Variant;
    sizeStyle?: Size;
    submit?: boolean;
}

function ButtonComponent({
    variant,
    children,
    submit,
    sizeStyle,
    disabled,
    ...rest
}: ButtonProps) {
    return (
        <button
            {...rest}
            className={classNames(
                "button",
                { "is-disabled": disabled },
                variant,
                sizeStyle
            )}
            type={submit ? "submit" : "button"}>
            {children}
        </button>
    );
}

interface IconProps extends React.HTMLProps<HTMLElement> {}
function Icon({ className, ...rest }: IconProps) {
    return <i {...rest} className={classNames("button-icon", className)} />;
}

export const Button = Object.assign(ButtonComponent, {
    Icon,
});
