function composeRoute(...parts: string[]) {
    const fragments = parts.map((part) => part.replace(/\//, ""));

    return `/${fragments.join("/")}`;
}

export const styleGuide = () => composeRoute("style-guide");

export const home = () => composeRoute("home");

export const members = () => composeRoute("mema");
export const membersLogin = () => composeRoute(members(), "login");
export const membersLoginWithEmail = () =>
    composeRoute(membersLogin(), "email");
export const membersLoginReset = () => composeRoute(membersLogin(), "reset");
export const membersLoginSetup = () => composeRoute(membersLogin(), "setup");
export const membersLoginNewPassword = () =>
    composeRoute(membersLogin(), "new-password");
export const membersLogout = () => composeRoute(members(), "logout");
export const membersDetails = () => composeRoute(members(), "moku");
export const membersDetailsEdit = () => composeRoute(membersDetails(), "edit");
export const membersDetailsGroups = () =>
    composeRoute(membersDetails(), "groups");

export const panui = () => composeRoute("panui", "*");
