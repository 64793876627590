import * as React from "react";
import { CallToActionData } from "@plinknz/tah-website-elements";
import { CallToAction } from "./call-to-action";

export type CallToActionName = "ComponentContentBlocksCallToAction";

export interface CallToActionProps {
    data: CallToActionData;
}

export const CallToActionBlock = ({ data }: CallToActionProps) => {
    const { callToActionTitle, description, action, background } = data;
    return (
        <CallToAction
            title={callToActionTitle}
            description={description}
            action={action}
            background={background}
        />
    );
};
