import * as React from "react";
import * as Survey from "survey-react";

import { PersonRequest } from "@plinknz/tah-website-elements";

import { BenefitForm } from "./benefit-form";
import { CustomerFormQuestionTypes } from "./customer-form-types";
import { BenefitType } from "../../../config/forms/";
import { createSurveyConfig } from "../../../service/forms/tapiki-aweawe-survey-config";
import { transformBenefitFormData } from "../../../utility/form-transforms/transform-benefit-form-data";

export const TapikiAweaweForm = () => {
    const transformData = (survey: Survey.ReactSurveyModel, member: PersonRequest) => {
        return transformBenefitFormData(survey, member, BenefitType.TapikiAweawe); 
    };
    
    return (
        <BenefitForm
            createSurveyConfig={createSurveyConfig}
            transformData={transformData}
            questionTypes={
                CustomerFormQuestionTypes.AddressFinder |
                CustomerFormQuestionTypes.BankAccount |
                CustomerFormQuestionTypes.FileUpload
            }
        />
    );
}