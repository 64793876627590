/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentTypeData } from "@plinknz/tah-website-elements";
import * as React from "react";
import Markdown from "react-markdown";

export interface ContentProps {
    data: ContentTypeData;
}

export interface LinkRendererProps {
    href: string;
    children: React.ReactNode;
}

const LinkRenderer = ({ children, href }: LinkRendererProps) => (
    <a href={href} target="_blank" rel="noreferrer noopener">
        {children}
    </a>
);

interface ImageRendererProps {
    alt: string;
    src: string;
}

// eslint-disable-next-line jsx-a11y/alt-text
const ImageRenderer = (props: ImageRendererProps) => <img {...props} />;

export const Content = ({ data }: ContentProps) => (
    <div data-testid="content">
        <Markdown
            className="content || constrain-width"
            linkTarget="blank"
            components={{
                a: ({ _node, ...props }: any) => {
                    return <LinkRenderer {...props} />;
                },
                img: ({ _node, ...props }: any) => {
                    return <ImageRenderer {...props} />;
                },
            }}>
            {data.content}
        </Markdown>
    </div>
);
