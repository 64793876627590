import * as React from "react";
import {
    parseYoutubeId,
    YoutubePlayerData,
} from "@plinknz/tah-website-elements";
import { useScript } from "../../utility/hooks/use-script";

export interface YoutubePlayerProps {
    data: YoutubePlayerData;
}

const DEFAULT_RENDER_TIMEOUT = 125;

export const YoutubePlayerContentBlock = ({ data }: YoutubePlayerProps) => {
    const playerRef = React.useRef<HTMLDivElement>();
    const videoId = parseYoutubeId(data.youtube_url);

    const renderPlayer = (timeout = DEFAULT_RENDER_TIMEOUT) => {
        if (typeof window.YT?.Player !== "undefined") {
            // eslint-disable-next-line no-new
            new YT.Player(playerRef.current, {
                videoId,
                playerVars: {
                    origin: window.location.origin,
                    modestbranding: 1,
                    rel: 0,
                },
            });
        } else {
            // HACK: to get multple videos on a page working
            setTimeout(() => renderPlayer(timeout * 2), timeout);
        }
    };

    useScript(playerRef, "https://www.youtube.com/iframe_api");

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
        const thisWindow = window as any;

        if (!thisWindow.onYouTubeIframeAPIReady) {
            thisWindow.onYouTubeIframeAPIReady = renderPlayer;
        } else {
            renderPlayer();
        }
    }, []);

    if (!videoId) {
        return null;
    }

    return (
        <section
            className="video || constrain-width"
            data-testid="youtube-player">
            {data.title && (
                <h3 className="heading-3 || video-header">{data.title}</h3>
            )}
            {data.description && (
                <p className="video-text">{data.description}</p>
            )}
            <div className="video-inner">
                <div className="video-player" ref={playerRef} />
            </div>
        </section>
    );
};
