const ENDPOINTS = {
    benefit: "benefit",
    interaction: "interaction",
    attachment: "attachment",
};

export const APPLIED_STATUS = "APPLIED_FOR_BENEFIT";

export enum BenefitType {
    KaiPahara = "Kai Pāhara Grant",
    Riuwaka = "Riuwaka Grant",
    ToiONgaRangi = "Toi o Ngā Rangi Grant",
    TapikiAweawe = "Tāpiki-Aweawe Grant",
    TapikiNuku = "Tāpiki-Nuku Grant",
    TapikiWaho = "Tāpiki-Waho Grant",
    TawhakiNuiAHema = "Tāwhaki-nui-a-Hema Scholarship",
    UruRangi = "Uru Rangi Scholarship",
    UruTau = "Uru Tau Scholarship",
    UruMatua = "Uru Matua Scholarship",
}

interface BenefitPayload {
    benefitDescription: BenefitType;
    benefitStatus: typeof APPLIED_STATUS;
    benefitDate: Number;
}

interface InteractionPayload {
    interaction: string;
    interactionDate: Number;
}

export interface GrantPayload {
    data: {
        benefit: BenefitPayload;
        interaction: InteractionPayload;
    },
    recaptcha: string | null;
    tenant: string;
}

export interface AttachmentResponse {
    data: {
        id: number;
        url: string;
    }[]
}

export function personEndpoint(id: number, endpoint: string) {
    return `/person/${id}/${endpoint}`;
}

export function benefitEndpoint(id: number) {
    return personEndpoint(id, ENDPOINTS.benefit);
}

export function interactionEndpoint(id: number) {
    return personEndpoint(id, ENDPOINTS.interaction);
}

export function attachmentEndpoint(id?: number) {
    return `/${ENDPOINTS.attachment}` + (id ? `/${id}` : "");
}