export enum ActionKind {
    ChangeName = "CHANGE_NAME",
    Reset = "RESET",
}

export type Actions = ChangeNameAction;

export const changeNameAction: (
    name: string,
    path: string
) => ChangeNameAction = (name, path) => ({
    type: ActionKind.ChangeName,
    payload: {
        name,
        path,
    },
});

export type ChangeNameAction = {
    type: ActionKind.ChangeName;
    payload: {
        name: string;
        path: string;
    };
};
