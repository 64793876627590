import * as React from "react";
import { generate } from "shortid";
import { LinksBlockData, Link } from "@plinknz/tah-website-elements";
import { formatMultilineText } from "../../utility/format-multiline-text";

interface LinksBlockProps {
    data: LinksBlockData;
}

export const LinksBlock = ({ data }: LinksBlockProps) => {
    const { title, description, links } = data;
    const renderItem = ({ title: itemTitle, url }: Link) => (
        <a
            href={url}
            className="links-block-item"
            key={generate()}
            rel="noreferrer"
            target="_blank"
            download>
            <div className="links-block-item-bullet">
                <h6 className="links-block-item-title">{itemTitle || url}</h6>
            </div>
            <div className="links-block-download">
                <i className="fas fa-upload fa-lg" />
            </div>
        </a>
    );

    return (
        <div
            className="links-block || constrain-width"
            data-testid="links-block">
            {title && (
                <h3 className="links-block-title || heading-3">{title}</h3>
            )}
            {description && (
                <div
                    className="links-block-description"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: formatMultilineText(description),
                    }}
                />
            )}
            <div className="links-block-list">{links.map(renderItem)}</div>
        </div>
    );
};
