// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { BankAccountType } from "../../components/form/bank-account";

export const validBankAccount = (bank: BankAccountType[]) => {
    const ac: BankAccountType = bank[0];
    const accString = ac.bank + ac.branch + ac.account + ac.suffix;
    const exactMatch = /^((\d{2})(\d{4})(\d{7})(\d{2,3}))$/;

    return !!accString.match(exactMatch);
};
