import * as React from "react";

import { BenefitForm } from "./benefit-form";
import { CustomerFormQuestionTypes } from "./customer-form-types";
import { createSurveyConfig } from "../../../service/forms/kai-pahara-survey-config";
import { transformKaiPaharaData } from "../../../utility/form-transforms/transform-kai-pahara-data";

export const KaiPaharaForm = () => (
    <BenefitForm
        createSurveyConfig={createSurveyConfig}
        transformData={transformKaiPaharaData}
        questionTypes={CustomerFormQuestionTypes.AddressFinder}
    />
);
