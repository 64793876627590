import * as React from "react";

export interface CheckboxProps {
    text: string;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    text,
}: CheckboxProps) => {
    return (
        <div className="checkbox form-row">
            <input
                className="checkbox-input"
                type="checkbox"
                name="checkbox"
                value={text}
            />
            <label className="checkbox-label">{text}</label>
        </div>
    );
};
