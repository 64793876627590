import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import * as ROUTES from "./config/router";
import { whataRequest } from "./service/member/whata";
import {
    initialUserState,
    UserContext,
    userReducer,
    USER_KEY,
} from "./service/reducers/user";
import { usePageView } from "./hooks/use-pageview";
import { Footer } from "./views/footer";
import { Header } from "./views/header";
import { Members } from "./views/members/members.component";
import { Page } from "./views/page";
import { Panui } from "./views/panui";
import { StyleGuide } from "./views/style-guide";

export const App = () => {
    const renderRoutes = () => (
        <Switch>
            <Route path={ROUTES.styleGuide()}>
                <StyleGuide />
            </Route>
            <Redirect from={ROUTES.home()} to="/" exact />
            <Redirect from="/members" to={ROUTES.members()} />
            <Route path={ROUTES.members()}>
                <Members />
            </Route>
            <Route path="/panui/:slug">
                <Panui />
            </Route>
            <Route path="*">
                <Page />
            </Route>
        </Switch>
    );

    usePageView();
    const [state, dispatch] = React.useReducer(userReducer, initialUserState());

    React.useEffect(() => {
        // Keeps user state in sync with localstorage
        localStorage.setItem(USER_KEY, JSON.stringify(state));

        if (state.whataToken) {
            whataRequest.token = state.whataToken;
        }
    }, [state]);

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            <div className="layout">
                <Header />
                <main className="main" role="main">
                    {renderRoutes()}
                </main>
                <Footer />
            </div>
        </UserContext.Provider>
    );
};

export type AppType = typeof App;
