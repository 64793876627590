import * as React from "react";

export interface RadioProps {
    label?: string;
    text: string;
}

export const Radio: React.FunctionComponent<RadioProps> = ({
    text,
}: RadioProps) => {
    return (
        <>
            <div className="radio">
                <input
                    className="input"
                    type="radio"
                    name="radio"
                    value={text}
                    id="radio-mr"
                />
                <label htmlFor="radio-mr">{text}</label>
            </div>
        </>
    );
};

export const RadioColumn: React.FunctionComponent<RadioProps> = ({
    label,
    text,
}: RadioProps) => {
    return (
        <>
            <label className="radio-label">{label}</label>
            <div className="radio-column">
                <Radio text={text} />
            </div>
        </>
    );
};
