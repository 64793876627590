/* istanbul ignore file */
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import * as Sentry from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import { setContext } from "@apollo/client/link/context";
import { ScrollToTop } from "@plinknz/tah-website-elements";
import { AppType, App } from "./app";

import "./styles/index.scss";
import { USER_KEY, UserState } from "./service/reducers/user";

if (process.env.SENTRY_DSN && process.env.SENTRY_DSN !== "undefined") {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: window.location.hostname,
    });
}

// Check that service workers are supported
if ("serviceWorker" in navigator) {
    // Use the window load event to keep the page load performant
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener("load", async () => {
        try {
            const registration = await navigator.serviceWorker.register(
                "/service-worker.js"
            );
            console.info("SW registered: ", registration);
        } catch (e: unknown) {
            console.warn("Could't start the service worker", e);
        }
    });
}

// eslint-disable-next-line no-underscore-dangle
const apolloCache = window.__APOLLO_STATE__;

const authLink = setContext((_, { headers }) => {
    const user = JSON.parse(localStorage.getItem(USER_KEY) ?? "") as UserState;

    return {
        headers: {
            ...(headers as unknown[]),
            authorization: user?.strapiToken
                ? `Bearer ${user?.strapiToken}`
                : "",
        },
    };
});

const httpLink = createHttpLink({
    uri: process.env.GRAPHQL_URL,
});

const client = new ApolloClient({
    cache: new InMemoryCache().restore(apolloCache),
    link: authLink.concat(httpLink),
    ssrMode: true,
});

const renderApp = (AppTree: AppType) => {
    const root = document.getElementById("root");
    let method: "hydrate" | "render" = "hydrate";

    if (root.childNodes.length === 0) {
        method = "render";
    }

    loadableReady(() => {
        ReactDOM[method](
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <ScrollToTop />
                    <AppTree />
                </BrowserRouter>
            </ApolloProvider>,
            root
        );
    });
};

renderApp(App);

if (module.hot) {
    const { hot } = module;

    hot.accept("./app.tsx", () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment,global-require
        const { App: nextApp } = require("./app");

        renderApp(nextApp);
    });
}
