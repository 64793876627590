import * as React from "react";
import { 
    CustomerFormTypeData,
} from "@plinknz/tah-website-elements";
import { KaiPaharaForm } from "./kai-pahara-form";
import { RiuwakaForm } from "./riuwaka-form";
import { TapikiAweaweForm } from "./tapiki-aweawe-form";
import { TapikiNukuForm } from "./tapiki-nuku-form";
import { TapikiWahoForm } from "./tapiki-waho-form";
import { TawhakiNuiAHemaForm } from "./tawhaki-nui-a-hema-form";
import { ToiONgaRangiForm } from "./toi-o-nga-rangi-form";
import { UruMatuaForm } from "./uru-matua-form";
import { UruRangiForm } from "./uru-rangi-form";
import { UruTauForm } from "./uru-tau-form";

interface CustomerFormBlockProps {
    data: CustomerFormTypeData;
}

export const CustomerFormBlock =
    ({ data: { active, type }}: CustomerFormBlockProps) => {
        if (!active) {
            return <div data-testid="inactive-form"></div>;
        }
        
        switch(type){
            case "Kai Pāhara":
                return <KaiPaharaForm />;
            case "Riuwaka":
                return <RiuwakaForm />;
            case "Toi o Ngā Rangi":
                return <ToiONgaRangiForm />;
            case "Tāpiki-Aweawe":
                return <TapikiAweaweForm />;
            case "Tāpiki-Nuku":
                return <TapikiNukuForm />;
            case "Tāpiki-Waho":
                return <TapikiWahoForm />;
            case "Tāwhaki-nui-a-Hema":
                return <TawhakiNuiAHemaForm />;
            case "Uru Matua":
                return <UruMatuaForm />;
            case "Uru Rangi":
                return <UruRangiForm />;
            case "Uru Tau":
                return <UruTauForm />;
            default:
                return <div data-testid="unknown-form"></div>;
        }
    }