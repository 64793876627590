import * as React from "react";
import { classNames } from "@plinknz/tah-website-elements";

interface AccordionProps {
    header: string;
    expand?: boolean;
    itemCount?: number;
    children: React.ReactNode;
}

export const Accordion = ({
    children,
    header,
    itemCount,
    expand = false,
}: AccordionProps) => {
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const handleClick = () => setOpen(!isOpen);
    const accordionClass = classNames("accordion", {
        "is-open": isOpen,
    });

    React.useEffect(() => {
        setOpen(expand);
    }, [expand]);

    return (
        <div className={accordionClass} data-testid="accordion">
            <div
                className="accordion-header"
                onKeyDown={(e) => (e.key === "Enter" ? handleClick() : null)}
                role="switch"
                aria-checked={isOpen}
                aria-label="Show content"
                tabIndex={0}
                onClick={handleClick}>
                <h4 className="heading-4" data-testid="accordion-header">
                    {header}{" "}
                    {itemCount && (
                        <small className="accordion-header-count">
                            ({itemCount})
                        </small>
                    )}
                </h4>
            </div>
            <div className="accordion-content" data-testid="accordion-content">
                {children}
            </div>
        </div>
    );
};
