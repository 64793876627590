import * as React from "react";

import { BenefitForm } from "./benefit-form";
import { CustomerFormQuestionTypes } from "./customer-form-types";
import { createSurveyConfig } from "../../../service/forms/riuwaka-survey-config";
import { transformRiuwakaData } from "../../../utility/form-transforms/transform-riuwaka-data";

export const RiuwakaForm = () => (
    <BenefitForm
        createSurveyConfig={createSurveyConfig}
        transformData={transformRiuwakaData}
        questionTypes={
            CustomerFormQuestionTypes.AddressFinder |
            CustomerFormQuestionTypes.BankAccount |
            CustomerFormQuestionTypes.FileUpload
        }
    />
);