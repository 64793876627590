import * as React from "react";
import * as SurveyTypes from "survey-react";
import { DatePicker } from "../form/datepicker";

export const DATEPICKER_KEY = "datepicker";

export function initializeDatepickerWidget(survey: typeof SurveyTypes) {
    const widget = {
        name: DATEPICKER_KEY,
        title: "Daypicker",
        iconName: "",
        isFit: (question: SurveyTypes.QuestionTextModel) =>
            question.getType() === DATEPICKER_KEY,

        activatedByChanged() {
            survey.JsonObject.metaData.addClass(
                DATEPICKER_KEY,
                [],
                null,
                "text"
            );
        },
        // eslint-disable-next-line react/display-name
        render: (question: SurveyTypes.QuestionTextModel) => (
            <DatePicker
                onChange={(value: Date) => {
                    // eslint-disable-next-line no-param-reassign
                    question.value = value;
                }}
                name="date"
            />
        ),
    };

    // Register our widget in singleton custom widget collection
    survey.CustomWidgetCollection.Instance.addCustomWidget(
        widget,
        "customtype"
    );
}
