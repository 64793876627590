import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function usePageView() {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            gtag("event", "screen_view", { screen_name: location.pathname });
        }
    }, [location]);

    return location;
}
