export const MOTHER = "mother" as const;
export const FATHER = "father" as const;

export const NAME_KEY = "name" as const;

export interface Parent {
    name?: string;
    mother?: Parent;
    father?: Parent;
}

export interface State {
    mother: Parent;
    father: Parent;
}

export const initialState: State = {
    [MOTHER]: {
        [NAME_KEY]: "",
    },
    [FATHER]: {
        [NAME_KEY]: "",
    },
};
