import * as React from "react";
import { classNames } from "@plinknz/tah-website-elements";

interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    error?: {
        hasError: boolean;
        message: string;
    };
}

export const Field = React.forwardRef<HTMLInputElement, FieldProps>(
    ({ label, name, error, required, type, ...rest }: FieldProps, ref) => {
        const [isTouched, setTouched] = React.useState(false);

        return (
            <div className="form-group">
                <div
                    className={classNames("field", {
                        error: error?.hasError && isTouched,
                        required,
                    })}
                    data-testid="field">
                    {type !== "hidden" && (
                        <label className="field-label" htmlFor={name}>
                            {label}
                        </label>
                    )}
                    <input
                        className="input"
                        name={name}
                        ref={ref}
                        onBlur={() => setTouched(true)}
                        type={type}
                        {...rest}
                    />
                    {error?.hasError && isTouched && (
                        <p className="field-error">{error.message}</p>
                    )}
                </div>
            </div>
        );
    }
);
