import * as React from "react";
import * as SurveyTypes from "survey-react";
import { Whakapapa } from "../form/whakapapa/whakapapa";
import { State } from "../form/whakapapa/parent-interfaces/interfaces";

export const WHAKAPAPA_KEY = "whakapapa";

export function initializeWhakapapaWidget(survey: typeof SurveyTypes) {
    const widget = {
        name: WHAKAPAPA_KEY,
        title: "Whakapapa",
        iconName: "",
        widgetIsLoaded() {
            return true;
        },
        isFit: (question: SurveyTypes.QuestionTextModel) =>
            question.getType() === WHAKAPAPA_KEY,

        activatedByChanged() {
            survey.JsonObject.metaData.addClass(
                WHAKAPAPA_KEY,
                [],
                null,
                "text"
            );
        },
        // eslint-disable-next-line react/display-name
        render: (question: SurveyTypes.QuestionTextModel) => (
            <Whakapapa
                onChange={(value: State) => {
                    // eslint-disable-next-line no-param-reassign
                    question.value = value;
                }}
            />
        ),
    };

    // Register our widget in singleton custom widget collection
    survey.CustomWidgetCollection.Instance.addCustomWidget(
        widget,
        "customtype"
    );
}
