import { Image, classNames } from "@plinknz/tah-website-elements";
import * as React from "react";
import { Helmet } from "react-helmet";
import { getMedia } from "../service/api-media";
import { ContentLoading } from "./content-loading";
import { Scroll } from "./scroll";

export interface HeroProps {
    title?: string;
    subtitle?: string | JSX.Element;
    image?: Image;
    size?: "large" | "normal";
    hasChildPages?: boolean;
    isLoading?: boolean;
}

export const Hero = ({
    title = "",
    subtitle = "",
    image,
    hasChildPages,
    size = "normal",
    isLoading,
}: HeroProps) => {
    const [imageUrl, setImageUrl] = React.useState<string>(
        image?.formats?.thumbnail.url // Use the smallest initially
    );
    const heroElement = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        // 600 so we don't get really small cover photos, should fix this in the future
        if (heroElement.current) {
            const minSize = 600;
            const targetSize = Math.max(
                heroElement.current.clientWidth,
                minSize
            );
            const url = getMedia(image?.url, {
                formats: image?.formats,
                targetSize,
            });

            if (url) {
                setImageUrl(url);
            } else if (!isLoading) {
                setImageUrl("/images/Whakarewa-Riuwaka-Resurgence.webp");
            }
        }
    }, [heroElement.current, image]);

    const heroClass = classNames("hero", {
        "has-image": !!imageUrl,
        "has-menu": hasChildPages,
        large: size === "large",
    });
    const backgroundImage = `url('${imageUrl}')`;

    return (
        <div className={heroClass} ref={heroElement} data-testid="hero">
            <Helmet>
                {title && <meta property="og:title" content={title} />}
                {image?.url && <meta property="og:image" content={image.url} />}
            </Helmet>

            <div
                className="hero-background"
                style={{
                    backgroundImage,
                }}
            />
            <div className="hero-content">
                <div className="title">
                    <h1 className="hero-heading || heading-1">
                        {(isLoading && <ContentLoading />) || title}
                    </h1>
                </div>

                <div className="subtitle">
                    {subtitle && (
                        <h4 className="hero-subheading">{subtitle}</h4>
                    )}
                </div>

                <Scroll />
            </div>
        </div>
    );
};
