import { ApiRequest } from "@plinknz/tah-website-elements";
import { registrationApi } from "../../../service/forms/register/api";
import { REGISTRATION_ENDPOINTS } from ".";

export interface Tupuna {
    id: number;
    value: string;
    nextId?: string;
}

interface SourceData {
    tupuna: Tupuna[];
}

interface TupunaProps {
    request?: ApiRequest;
    onLoad?: () => void;
}

export class Tupuna {
    public loaded: boolean = false;
    private data: Tupuna[] = [];
    public names: string[] = [];

    constructor({ request = registrationApi, onLoad }: TupunaProps) {
        if (this.loaded) {
            return;
        }

        request
            .get<SourceData>(REGISTRATION_ENDPOINTS.groups)
            .then((response) => {
                const cleanData: Tupuna[] = response.data.tupuna.sort(
                    (a: Tupuna, b: Tupuna) => {
                        if (a.value > b.value) {
                            return 1;
                        }
                        if (a.value < b.value) {
                            return -1;
                        }
                        return 0;
                    }
                );

                this.names = cleanData.map((rec) => rec.value);
                this.data = cleanData;
                this.loaded = true;
                if (onLoad) onLoad();
            });
    }

    public getTupunaId(name: string) {
        const row = this.data.filter((rec) => rec.value === name);
        if (row.length === 0) {
            return undefined;
        }
        return {
            id: row[0].id,
            nextId: row[0].nextId,
        };
    }
}
