/* eslint-disable react/destructuring-assignment,no-param-reassign,@typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from "react";
import {
    AddressFinderInput,
    AddressMeta,
    Address,
} from "./address-finder-input";

export const ADDRESS_FINDER_KEY = "addressfinder";

interface AddressFinderProps {
    onChange: (address: Address) => void;
}

export const AddressFinder = ({ onChange }: AddressFinderProps) => {
    const [line1, setLine1] = useState<string>("");
    const [line2, setline2] = useState<string>("");
    const [suburb, setSuburb] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [postcode, setPostcode] = useState<string>("");
    const [country, setCountry] = useState<string>("");

    useEffect(() => {
        onChange({
            line1,
            line2,
            suburb,
            city,
            postcode,
            country,
        });
    }, [line1, line2, suburb, city, postcode, country]);

    return (
        <div className="form-row">
            <div className="form-group">
                <h3> Address </h3>
                <div className="form-row">
                    <div className="form-group">
                        <AddressFinderInput
                            id="address-finder-input"
                            className="input"
                            placeholder="Start typing your address here..."
                            addressfinderkey={process.env.ADDRESS_FINDER_KEY}
                            country="NZ"
                            onSelected={(
                                _fullAddress: string,
                                address: AddressMeta
                            ) => {
                                setLine1(address.address_line_1);
                                setline2(address.address_line_2);
                                setSuburb(address.suburb);
                                setCity(address.city);
                                setPostcode(address.postcode);
                                setCountry(address.country);
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            className="input"
                            id="line1"
                            type="text"
                            placeholder="Address Line 1"
                            value={line1}
                            onChange={(event) => {
                                setLine1(event.currentTarget.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="input"
                            id="line2"
                            type="text"
                            placeholder="Address Line 2"
                            value={line2}
                            onChange={(event) => {
                                setline2(event.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            className="input"
                            id="suburb"
                            type="text"
                            placeholder="Suburb"
                            value={suburb}
                            onChange={(event) => {
                                setSuburb(event.currentTarget.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="input"
                            id="city"
                            type="text"
                            placeholder="City"
                            value={city}
                            onChange={(event) => {
                                setCity(event.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            className="input"
                            id="postcode"
                            type="text"
                            placeholder="Postcode"
                            value={postcode}
                            onChange={(event) => {
                                setPostcode(event.currentTarget.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="input"
                            id="country"
                            type="text"
                            placeholder="Country"
                            value={country}
                            onChange={(event) => {
                                setCountry(event.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
