import * as React from "react";
import { GoogleCalendarTypeData } from "@plinknz/tah-website-elements";

export interface GoogleCalendarProps {
    data: GoogleCalendarTypeData;
}

export const GoogleCalendar = ({
    data: { calendarId },
}: GoogleCalendarProps) => {
    const src = `https://calendar.google.com/calendar/embed?showTz=0&showTitle=0&showCalendars=0&showPrint=0&src=${calendarId}%40group.calendar.google.com&ctz=Pacific%2FAuckland%2 `;

    return (
        <div
            className="google-calendar constrain-width"
            data-testid="google-calendar">
            <iframe
                className="google-calendar-inner"
                src={src}
                title="Wātaka Kūkara"
            />
        </div>
    );
};
