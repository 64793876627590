import * as React from "react";

export const Scroll = () => (
    <div className="scroll">
        <div className="scroll-rotate">
            <div className="scroll-link">
                <span className="scroll-link-arrow">
                    <span className="scroll-link-arrow-line" />
                    <span className="scroll-link-arrow-line" />
                </span>
                <span className="scroll-link-line" />
                <span className="scroll-link-text">Scroll</span>
            </div>
        </div>
    </div>
);
