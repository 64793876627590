import React, { Dispatch } from "react";
import { changeNameAction, Actions } from "./types";
import { MOTHER, FATHER, Parent, State } from "./parent-interfaces/interfaces";
// eslint-disable-next-line import/no-cycle
import { WhakapapaGroup } from "./group";

export interface ParentGroupProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    parent: Parent;
    layer: number;
    stateKey: string;
    dispatch: Dispatch<Actions>;
    state: State;
    ref?: string;
}

export const ParentGroup = ({
    parent,
    dispatch,
    state,
    stateKey,
    type,
    layer,
    ...rest
}: ParentGroupProps) => {
    return (
        <div className="whakapapa-parent-group">
            <WhakapapaGroup
                label={`${parent.name}'s Mother`}
                data-testid={`whakapapa-mama-${parent.name}`}
                placeholder={`${parent.name}'s Mother`}
                stateKey={`${stateKey}.${MOTHER}`}
                type={type}
                onUpdate={(value) => {
                    const nextStateKey = `${stateKey}.${MOTHER}`;

                    dispatch(changeNameAction(value, nextStateKey));
                }}
                layer={layer}
                parent={parent.mother}
                dispatch={dispatch}
                state={state}
                {...rest}
            />

            <WhakapapaGroup
                label={`${parent.name}'s Father`}
                data-testid={`whakapapa-papa-${parent.name}`}
                placeholder={`${parent.name}'s Father`}
                stateKey={`${stateKey}.${FATHER}`}
                type={type}
                onUpdate={(value) => {
                    const nextStateKey = `${stateKey}.${FATHER}`;

                    dispatch(changeNameAction(value, nextStateKey));
                }}
                layer={layer}
                parent={parent.father}
                dispatch={dispatch}
                state={state}
                {...rest}
            />
        </div>
    );
};
