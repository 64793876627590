import {
    PictureListItemData,
    PictureListTypeData,
    classNames,
    formatMultilineText,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import { useVisible } from "react-visible-image";
import { generate } from "shortid";

export interface PictureListProps {
    data: PictureListTypeData;
}

const PictureListItem = ({
    title,
    picture,
    subtitle,
    description,
}: PictureListItemData) => {
    const item = React.useRef<HTMLDivElement>(null);
    const isVisible = useVisible(item);
    const profileClass = classNames("profile");

    return (
        <div className={profileClass} ref={item} key={generate()}>
            <h2 className="profile-content-title">{title}</h2>
            <div className="profile-columns">
                <div
                    className="profile-image right bottom"
                    style={{
                        backgroundImage: isVisible
                            ? `url('${picture?.url}')`
                            : "",
                    }}
                />
                <div className="profile-columns">
                    <div className="profile-columns-text">
                        {subtitle && (
                            <h2 className="profile-subtitle">{subtitle}</h2>
                        )}
                        {description && (
                            <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: formatMultilineText(description),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const PictureList = ({ data }: PictureListProps) => {
    return (
        <div
            className="profile-wrapper || constrain-width"
            data-testid="profile">
            <h3>{data.profileHeading}</h3>
            <div>{data?.profiles.map(PictureListItem)}</div>
        </div>
    );
};
