import * as React from "react";
import { VisibleImage } from "react-visible-image";
import { generate } from "shortid";
import SwiperCore, {
    A11y,
    Autoplay,
    Navigation,
    Pagination,
    Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GalleryBlockData } from "@plinknz/tah-website-elements";
import { canUseDom } from "../../utility/environment";
import { formatMultilineText } from "../../utility/format-multiline-text";

let SafeSwiper: React.FunctionComponent<Swiper> = React.Fragment;
let SafeSwiperSlide: React.FunctionComponent<SwiperSlide> = React.Fragment;

if (canUseDom()) {
    SafeSwiper = Swiper;
    SafeSwiperSlide = SwiperSlide;

    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
}

export type GalleryBlockName = "ComponentContentBlocksImageGallery";

interface GalleryProps {
    data: GalleryBlockData;
}

export interface ImageElement {
    heading?: string;
    description?: string;
    image: ImageImage;
}

export interface ImageImage {
    url: string;
    alternativeText: string;
    width: number;
    height: number;
}

const MAX_WIDTH = 960;
const MAX_GAP = 80;
const MIN_GAP = 40;
const MAX_COLUMNS = 40;

export const GalleryBlock = ({ data }: GalleryProps) => {
    const { description, images, heading, autoplay, galleryId } = data;
    const hasMany = images.length > 1;
    const gap = hasMany ? MAX_GAP : MIN_GAP;
    const width = canUseDom()
        ? Math.min(window.innerWidth, MAX_WIDTH) - gap
        : 0;
    const id = galleryId || generate();
    const autoplayConfig = autoplay ? { delay: 3000 } : false;

    const renderNavigationButton = (direction: "next" | "prev") => (
        <div
            className="gallery-navigation-section"
            data-gallery-nav={`${id}-${direction}`}>
            <div
                className={`gallery-button gallery-button-${direction}`}
                data-testid={`galley-button-${direction}`}>
                <i
                    className={`fas fa-chevron-circle-${
                        direction === "next" ? "right" : "left"
                    }`}
                />
            </div>
        </div>
    );

    return (
        <div className="gallery" id={id} data-testid="gallery">
            {(heading || description) && (
                <div className="constrain-width">
                    {heading && <h3 className="gallery-title">{heading}</h3>}
                    {description && (
                        <div
                            className="gallery-description"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: formatMultilineText(description),
                            }}
                        />
                    )}
                </div>
            )}
            {canUseDom() && (
                <SafeSwiper
                    allowTouchMove={hasMany}
                    className="gallery-container"
                    spaceBetween={gap / MAX_COLUMNS}
                    navigation={{
                        nextEl: `[data-gallery-nav="${id}-next"]`,
                        prevEl: `[data-gallery-nav="${id}-prev"]`,
                        disabledClass: "is-disabled",
                    }}
                    autoplay={autoplayConfig}
                    loop
                    loopAdditionalSlides={2}
                    loopedSlides={1}
                    slideActiveClass="is-active"
                    slidesPerView="auto"
                    width={width}
                    pagination={{
                        type: "fraction",
                    }}
                    onResize={(swiper) => swiper.updateSize()}
                    onSlideChange={(swiper) => swiper.updateSize()}>
                    {images.map(
                        ({
                            heading: slideHeading,
                            description: slideDescription,
                            image: { url, alternativeText },
                        }) => (
                            <SafeSwiperSlide
                                className="gallery-slide"
                                key={generate()}>
                                <div
                                    className="gallery-slide-image"
                                    title={alternativeText}
                                    style={{
                                        backgroundImage: `url("${url}")`,
                                    }}>
                                    <VisibleImage
                                        className="hidden"
                                        src={url}
                                        alt={alternativeText}
                                    />
                                </div>
                                {(slideHeading || slideDescription) && (
                                    <div className="gallery-slide-info">
                                        {slideHeading && (
                                            <h4 className="gallery-slide-title">
                                                {slideHeading}
                                            </h4>
                                        )}
                                        {slideDescription && (
                                            <p className="gallery-slide-description">
                                                {slideDescription}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </SafeSwiperSlide>
                        )
                    )}
                    {hasMany && (
                        <div className="gallery-navigation">
                            <div className="gallery-navigation-inner constrain-width-large no-pad">
                                {renderNavigationButton("prev")}
                                {renderNavigationButton("next")}
                            </div>
                        </div>
                    )}
                </SafeSwiper>
            )}
        </div>
    );
};
