import React, { useReducer, useEffect } from "react";
import _ from "lodash";
// eslint-disable-next-line import/no-cycle
import { WhakapapaGroup } from "./group";
import { Actions, ActionKind, changeNameAction } from "./types";
import {
    NAME_KEY,
    MOTHER,
    FATHER,
    initialState,
    State,
} from "./parent-interfaces/interfaces";

function reducer(state: State, action: Actions) {
    switch (action.type) {
        case ActionKind.ChangeName: {
            const { name, path } = action.payload;
            const nextState = { ...state };

            _.set(nextState, `${path}.${NAME_KEY}`, name);

            const mother = _.get(nextState, `${path}.${MOTHER}`);
            const father = _.get(nextState, `${path}.${MOTHER}`);

            if (name !== "" && (!mother || !father)) {
                _.set(nextState, `${path}.${MOTHER}`, { name: "" });
                _.set(nextState, `${path}.${FATHER}`, { name: "" });
            }

            return nextState;
        }
        default:
            return state;
    }
}

interface WhakapapaProps {
    onChange: (whakapapa: State) => void;
    title?: string;
    text?: string;
}

export const Whakapapa = ({ onChange, title, text }: WhakapapaProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const layer = 0;

    useEffect(() => {
        onChange(state);
    }, [state]);

    return (
        <div className="whakapapa form">
            <WhakapapaGroup
                title={title}
                text={text}
                data-testid="input-mama"
                label="Mother"
                stateKey="mother"
                onUpdate={(name) => dispatch(changeNameAction(name, MOTHER))}
                placeholder={state.mother.name}
                parent={state.mother}
                layer={layer}
                dispatch={dispatch}
                state={state}
            />

            <WhakapapaGroup
                data-testid="input-papa"
                label="Father"
                stateKey="father"
                onUpdate={(name) => dispatch(changeNameAction(name, FATHER))}
                placeholder={state.father.name}
                parent={state.father}
                layer={layer}
                state={state}
                dispatch={dispatch}
            />
        </div>
    );
};
