import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as SurveyType from "survey-react";

import {
    ApiRequest,
    PersonRequest,
    ProfileData,
    WHATA_ENDPOINTS,
} from "@plinknz/tah-website-elements";

import { CustomerForm, SurveyOptions } from "./customer-form";
import { CustomerFormQuestionTypes } from "./customer-form-types";
import {
    AttachmentResponse,
    GrantPayload,
    attachmentEndpoint,
    benefitEndpoint,
    interactionEndpoint,
    personEndpoint,
} from "../../../config/forms";

import { Loader } from "../../../components/loader";
import { whataRequest } from "../../../service/member/whata";
import { UserContext, UserData } from "../../../service/reducers/user";
import { fetchUser } from "../../../utility/load-member";

interface BenefitFormProps {
    createSurveyConfig: (member: PersonRequest, user?: UserData) => {pages: any[]};
    transformData: (
        survey: SurveyType.ReactSurveyModel,
        member: PersonRequest,
    ) =>  GrantPayload;
    questionTypes: CustomerFormQuestionTypes;
    request?: ApiRequest;
}

function demacronise(val: string) {
    if (!val) {
        return val;
    }
    
    return (val as any)
        .replaceAll(/ā/gi, 'a')
        .replaceAll(/ē/gi, 'e')
        .replaceAll(/ī/gi, 'i')
        .replaceAll(/ō/gi, 'o')
        .replaceAll(/ū/gi, 'u')
        .replaceAll(/Ã/gi, 'A')
        .replaceAll(/Ē/gi, 'E')
        .replaceAll(/Ī/gi, 'I')
        .replaceAll(/Õ/gi, 'O')
        .replaceAll(/Ū/gi, 'U');
}

export const BenefitForm = ({
    createSurveyConfig,
    transformData,
    questionTypes,
    request = whataRequest,
}: BenefitFormProps) => {
    const [loading, updateLoading] = React.useState<boolean>(false);
    const [apiLoading, updateApiLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>(null);
    const {
        state: { user, member },
        dispatch,
    } = React.useContext(UserContext);
    const history = useHistory();
    const location = useLocation();

    async function populateUser() {
        if (!loading) {
            updateLoading(true);
            await fetchUser({
                dispatch,
                history,
                id: user.id,
                location,
            });
            updateLoading(false);
        }
    }

    async function checkLoginStale() {
        // Stale token will result in a non-json (empty) response whivh will
        // cause the get function to throw an error
        
        try {
            await request.get<ProfileData>(WHATA_ENDPOINTS.me());
        }
        catch (_) {
            dispatch({ type: "set_member", member: null})
            populateUser();
        }
    }

    React.useEffect(() => {
        if (user?.id) {
            if (member) {
                checkLoginStale();
            } else {
                populateUser();
            }
        }
    }, [user?.id]);
    
    if (loading) {
        return (
            <div className="splash">
                <Loader size="large" />
            </div>
        );
    }

    if (!member) {
        return (<div data-testid="no-member"></div>);
    }

    const surveyConfig = createSurveyConfig(member, user);

    const uploadFiles = async (options: SurveyOptions) => {
        const uploadedFiles: {file: File, content: string}[] = [];
        for (const file of options.files) {
            const safeFile = new File([file], demacronise(file.name));
            const formData = new FormData();
            
            formData.append("file", safeFile);
            const { data } = await request.post(attachmentEndpoint(), formData);
            await request.post(
                attachmentEndpoint(data as number),
                {members: [member.id]},
            );
            const files: AttachmentResponse = await request.get(
                personEndpoint(member.id, "attachment")
            ) as any as AttachmentResponse;

            const { url } = files.data.find((file) => file.id === data);
            uploadedFiles.push({
                file,
                content: url,
            });
        }
        options.callback('success', uploadedFiles);
    };

    const sendDataToServer = async (survey: SurveyType.ReactSurveyModel) => {
        updateApiLoading(true);

        try {
            const payload = transformData(survey, member);
            await request.post(benefitEndpoint(member.id), payload.data.benefit);
            await request.post(interactionEndpoint(member.id), payload.data.interaction);
        } catch (sendError) {
            console.error(sendError);

            if (sendError instanceof Error) {
                setError(sendError.message);
            }
        } finally {
            updateApiLoading(false);
        }
    };

    return (
        <CustomerForm
            onComplete={sendDataToServer}
            apiRequestInProgress={apiLoading}
            apiError={error}
            surveyConfig={surveyConfig}
            questionTypes={questionTypes}
            onFileUpload={uploadFiles}
        />
    );
};
