import * as React from "react";
import * as SurveyType from "survey-react";

import { lazy } from "@loadable/component";
import { ServerSuspense } from "@plinknz/tah-website-elements";

import { CustomerFormQuestionTypes } from "./customer-form-types";
import { Loader } from "../../loader";
import { initializeAddressFinderWidget } from "../../survey-widgets/address-finder-widget";
import { initializeDatepickerWidget } from "../../survey-widgets/datepicker-widget";
import { initializeBankAccountWidget } from "../../survey-widgets/nz-bank-account-widget";
import { validBankAccount } from "../../../config/survey-validation/valid-bankaccount";
import { applySurveyConfig } from "../../../utility/apply-survey-config";

export interface SurveyOptions {
    files: File[],
    callback: (
        status: string,
        files?: { file: File, content: string }[]
    ) => void,
}

export interface CustomerFormProps {
    onComplete: (survey: SurveyType.ReactSurveyModel) => void;
    apiRequestInProgress: boolean;
    apiError: string;
    surveyConfig: { pages: any[] };
    questionTypes: CustomerFormQuestionTypes;
    onFileUpload?: ({ files, callback }: SurveyOptions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SurveyComponent = lazy<any>(async () =>
    import(`survey-react`).then((module) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        default: module.Survey,
    }))
);

export const CustomerForm = ({
    onComplete,
    apiRequestInProgress,
    apiError,
    surveyConfig,
    questionTypes,
    onFileUpload,
}: CustomerFormProps) => {
    const [surveyModel, updateSurvey] = React.useState<
        SurveyType.ReactSurveyModel
    >(null);
    const [loading, updateLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>(null);

    React.useEffect(() => {
        (async () => {
            if (loading || error || surveyModel) {
                return;
            }

            try {
                updateLoading(true);
                const survey = await import(
                    /* webpackChunkName: "survey-react" */ "survey-react"
                );
                await import(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    /* webpackChunkName: "surveyjs-widgets" */ "surveyjs-widgets" as any
                );

                // TODO: The run time imports above make it difficult to test
                // this without getting not wrapped in act errors
                // Need to figure out how to do this

                applySurveyConfig(survey);
                
                if ((questionTypes & CustomerFormQuestionTypes.DatePicker) === CustomerFormQuestionTypes.DatePicker) {
                    initializeDatepickerWidget(survey);
                }
                if ((questionTypes & CustomerFormQuestionTypes.AddressFinder) === CustomerFormQuestionTypes.AddressFinder) {
                    initializeAddressFinderWidget(survey);
                }
                if ((questionTypes & CustomerFormQuestionTypes.BankAccount) === CustomerFormQuestionTypes.BankAccount) {
                    initializeBankAccountWidget(survey);

                    survey.FunctionFactory.Instance.register(
                        "validBankAccount",
                        validBankAccount,
                        false
                    );
                }
                const surveyInstance = new survey.Model(surveyConfig);

                surveyInstance.locale = "my";
                
                if ((questionTypes & CustomerFormQuestionTypes.FileUpload) === CustomerFormQuestionTypes.FileUpload &&
                    onFileUpload){
                    surveyInstance.onUploadFiles.add((_, options) => {
                        onFileUpload(options);
                    });
                }
    
                updateSurvey(surveyInstance);
            } catch (fetchError) {
                console.warn("Error: ", error);
                setError(
                    error ||
                        "There was an error trying to retrieve information from the server. Please reload and try again."
                );
            } finally {
                updateLoading(false);
            }
        })();
    }, []);

    return (
        <div
            className="customer-form || constrain-width"
            data-testid="customer-form">
            {((loading || apiRequestInProgress) && (
                <div className="splash">
                    <Loader size="large" />
                </div>
            )) ||
                ((error || apiError) && (
                    <div data-testid="error">
                        <p>{error || apiError}</p>
                    </div>
                )) || (
                    <ServerSuspense fallback={<i />}>
                        <SurveyComponent
                            data-testid="customer-form-survey"
                            model={surveyModel}
                            onComplete={onComplete}
                        />
                    </ServerSuspense>
                )}
        </div>
    );
};
