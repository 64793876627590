import * as React from "react";

export interface BlockQuoteProps {
    text: string;
}

export const BlockQuote: React.FunctionComponent<BlockQuoteProps> = ({
    text,
}: BlockQuoteProps) => {
    return (
        <>
            <blockquote>
                <p>{text}</p>
            </blockquote>
        </>
    );
};
