import {
    ApiRequest,
    canUseDom,
    Recaptcha,
    RegistrationTypeData,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import * as SurveyType from "survey-react";
import { Loader } from "../../loader";
import { Survey } from "../../survey";
import { REGISTRATION_ENDPOINTS } from "../../../config/forms/register";
import { RegisterPayload } from "../../../config/forms/register/payload";
import { Tupuna } from "../../../config/forms/register/tupuna";
import { registrationApi } from "../../../service/forms/register/api";
import { createSurveyConfig } from "../../../service/forms/register/survey-config";
import { initializeAddressFinderWidget } from "../../survey-widgets/address-finder-widget";
import { initializeDatepickerWidget } from "../../survey-widgets/datepicker-widget";
import { initializeWhakapapaWidget } from "../../survey-widgets/whakapapa-widget";
import { applySurveyConfig } from "../../../utility/apply-survey-config";
import { transformRegistrationFormData } from "../../../utility/form-transforms/transform-registration-form-data";

export interface RegistrationProps {
    data: RegistrationTypeData;
    request?: ApiRequest;
}
let payload: RegisterPayload = null;

export const RegistrationForm = ({
    data: { active },
    request = registrationApi,
}: RegistrationProps) => {
    const [surveyModel, updateSurvey] = React.useState<
        SurveyType.ReactSurveyModel
    >(null);
    const [surveyLoading, updateSurveyLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>(null);
    const [recaptchaLoading, updateRecaptchaLoading] = React.useState<boolean>(
        true
    );
    const [tupunaLoaded, updateTupunaLoaded] = React.useState<boolean>(false);
    const [tupuna, updateTupuna] = React.useState<Tupuna>();
    
    React.useEffect(() => {
        const loadTupuna = new Tupuna({
            request,
            onLoad: () => updateTupunaLoaded(true),
        });
        updateTupuna(loadTupuna);
    }, []);

    if (!active && canUseDom()) {
        return null;
    }

    const handleRecaptcha = async (token: string) => {
        payload = {
            ...payload,
            recaptcha: token,
        };

        await request.post(REGISTRATION_ENDPOINTS.register, payload);
    };

    const sendDataToServer = async (survey: SurveyType.ReactSurveyModel) => {
        updateSurveyLoading(true);

        try {
            grecaptcha.execute();
            const { id, nextId } = tupuna
                .getTupunaId(survey.data.tupuna);
            payload = transformRegistrationFormData(survey, id.toString(), nextId);
        } catch (sendError) {
            console.error(sendError);

            if (sendError instanceof Error) {
                setError(sendError.message);
            }
        } finally {
            updateSurveyLoading(false);
        }
    };

    const recaptchaLoaded = () => updateRecaptchaLoading(false);

    React.useEffect(() => {
        (async () => {
            if (
                !tupunaLoaded ||
                recaptchaLoading ||
                surveyLoading ||
                error ||
                surveyModel
            ) {
                return;
            }

            try {
                updateSurveyLoading(true);
                const survey = await import(
                    /* webpackChunkName: "survey-react" */ "survey-react"
                );
                await import(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    /* webpackChunkName: "surveyjs-widgets" */ "surveyjs-widgets" as any
                );

                // TODO: The run time imports above make it difficult to test
                // this without getting not wrapped in act errors
                // Need to figure out how to do this

                applySurveyConfig(survey);
                initializeDatepickerWidget(survey);
                initializeAddressFinderWidget(survey);
                initializeWhakapapaWidget(survey);

                const result = await createSurveyConfig({
                    tupunaChoices: tupuna.names,
                });
                const surveyInstance = new survey.Model(result);
                surveyInstance.locale = "my";

                updateSurvey(surveyInstance);
            } catch (fetchError) {
                console.warn("Error: ", fetchError);
                setError(
                    fetchError ||
                        "There was an error trying to retrieve information from the server. Please reload and try again."
                );
            } finally {
                updateSurveyLoading(false);
            }
        })();

        return () => {
            if (surveyModel) surveyModel.clear();
            updateSurvey(null);
        };
    }, [recaptchaLoading, tupunaLoaded]);

    return (
        <div
            className="customer-form || constrain-width"
            data-testid="registration-form">
            {((recaptchaLoading || surveyLoading || !tupunaLoaded) && (
                <div className="splash">
                    <Loader size="large" />
                </div>
            )) ||
                (error && (
                    <div data-testid="error">
                        <p>{error}</p>
                    </div>
                )) || (
                    <Survey model={surveyModel} onComplete={sendDataToServer} />
                )}
            <Recaptcha onReady={recaptchaLoaded} onSubmit={handleRecaptcha} />
        </div>
    );
};
