import React, { Dispatch } from "react";
import _ from "lodash";
import { Actions } from "./types";
// eslint-disable-next-line import/no-cycle
import { ParentGroup } from "./parent";
import { NAME_KEY, Parent, State } from "./parent-interfaces/interfaces";

export interface WhakapapaGroupProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onUpdate: (value: string) => void;
    label: string;
    layer: number;
    parent: Parent;
    stateKey: string;
    state: State;
    text?: string;
    title?: string;
    dispatch: Dispatch<Actions>;
}

export const WhakapapaGroup = ({
    onUpdate,
    parent,
    dispatch,
    stateKey,
    state,
    label,
    type,
    placeholder = "",
    layer,
    text,
    title,
    ...rest
}: WhakapapaGroupProps) => {
    return (
        <div className="whakapapa-group">
            <h3>{title}</h3>
            <p>{text}</p>
            {type !== "hidden" && (
                <label className="whakapapa-label">{label}</label>
            )}
            <input
                data-testid="whakapapa-input"
                className="whakapapa-input"
                placeholder={placeholder}
                onChange={(event) => {
                    onUpdate(event.target.value);
                }}
                value={_.get(state, `${stateKey}.${NAME_KEY}`)}
                type={type}
                {...rest}
            />
            {parent?.name !== "" && (
                <ParentGroup
                    stateKey={stateKey}
                    dispatch={dispatch}
                    parent={parent}
                    state={state}
                    layer={layer + 1}
                />
            )}
        </div>
    );
};
