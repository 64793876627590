import { DATEPICKER_KEY } from "../../../components/survey-widgets/datepicker-widget";

export function getTamarikiList() {
    const tamarikiMax = 20;
    const tamarikiList = [];
    let tamarikiCount = 1;

    while (tamarikiCount < tamarikiMax) {
        const visibleIf =
            tamarikiCount === 1 ? "true" :
                `{tamaiti${tamarikiCount - 1}FirstName} notempty`;
        const requiredIf = `{tamaiti${tamarikiCount}FirstName} notempty`;
        const requiredIfName = `{tamaiti${tamarikiCount + 1}FirstName} notempty`;

        tamarikiList.push({
            type: "text",
            title: `Tamaiti ${tamarikiCount}: First Names`,
            name: `tamaiti${tamarikiCount}FirstName`,
            placeHolder: 'Ingoa',
            visibleIf,
            requiredIf: requiredIfName,
        }, {
            type: "text",
            title: `Tamaiti ${tamarikiCount}: Surname`,
            name: `tamaiti${tamarikiCount}Surname`,
            placeHolder: 'Ingoa Whānau',
            visibleIf,
            startWithNewLine: false,
            requiredIf,
        }, {
            type: "radiogroup",
            title: `Tamaiti ${tamarikiCount}: Gender`,
            name: `tamaiti${tamarikiCount}Gender`,
            choices: ["Wahine", "Tāne", "Ira Weherua-kore"],
            visibleIf: requiredIf,
            requiredIf,
        }, {
            type: DATEPICKER_KEY,
            inputType: "date",
            name: `tamaiti${tamarikiCount}Dob`,
            title: `Tamaiti ${tamarikiCount}: Date of Birth`,
            visibleIf: requiredIf,
            requiredIf,
        }, {
            type: "text",
            title: `Tamaiti ${tamarikiCount}: Birthplace`,
            name: `tamaiti${tamarikiCount}Birthplace`,
            placeHolder: 'Wāhi Whānau',
            visibleIf: requiredIf,
            requiredIf,
            startWithNewLine: false,
        }, {
            type: "radiogroup",
            title: `Tamaiti ${tamarikiCount}: Deceased`,
            name: `tamaiti${tamarikiCount}Deceased`,
            choices: ["Kāo", "Āe"],
            defaultValue: "Kāo",
            visibleIf: requiredIf,
        }, {
            type: "text",
            title: `Tamaiti ${tamarikiCount}: Burial Place`,
            name: `tamaiti${tamarikiCount}BurialPlace`,
            placeHolder: 'Wāhi Nehu',
            visibleIf: `{tamaiti${tamarikiCount}Deceased} = "Āe"`,
            required: "true",
            startWithNewLine: false,
        }, {
            type: DATEPICKER_KEY,
            inputType: "date",
            name: `tamaiti${tamarikiCount}Dod`,
            title: `Tamaiti ${tamarikiCount}: Date of Death`,
            visibleIf: `{tamaiti${tamarikiCount}Deceased} = "Āe"`,
            required: "true",
            startWithNewLine: false,
        });

        tamarikiCount += 1;
    }

    return tamarikiList;
}
