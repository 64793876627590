import * as React from "react";
import * as SurveyType from "survey-react";
import { lazy } from "@loadable/component";
import { ServerSuspense } from "@plinknz/tah-website-elements";

export interface SurveyProps {
    model: SurveyType.ReactSurveyModel;
    onComplete: (survey: SurveyType.ReactSurveyModel) => void;
}

// TODO: move to tah-website-elements

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SurveyComponent = lazy<any>(async () =>
    import(`survey-react`).then((module) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        default: module.Survey,
    }))
);

export const Survey = ({ model, onComplete }: SurveyProps) => {
    return (
        <ServerSuspense fallback={<i data-testid="lazy-fallback" />}>
            {model &&
                <SurveyComponent
                    model={model}
                    onComplete={onComplete}
                />
            }
        </ServerSuspense>
    );
};