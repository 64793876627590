import * as React from "react";
import * as SurveyTypes from "survey-react";
import { BankAccount, BankAccountType } from "../form/bank-account";

export const BANK_ACCOUNT_KEY = "bankaccount";

export function initializeBankAccountWidget(survey: typeof SurveyTypes) {
    const widget = {
        name: BANK_ACCOUNT_KEY,
        title: "BankAccount",
        iconName: "",
        isFit: (question: SurveyTypes.QuestionTextModel) =>
            question.getType() === BANK_ACCOUNT_KEY,

        activatedByChanged() {
            survey.JsonObject.metaData.addClass(
                BANK_ACCOUNT_KEY,
                [],
                null,
                "text"
            );
        },
        // eslint-disable-next-line react/display-name
        render: (question: SurveyTypes.QuestionTextModel) => (
            <BankAccount
                onChange={(value: BankAccountType) => {
                    // eslint-disable-next-line no-param-reassign
                    question.value = value;
                }}
            />
        ),
    };

    // Register our widget in singleton custom widget collection
    survey.CustomWidgetCollection.Instance.addCustomWidget(
        widget,
        "customtype"
    );
}
