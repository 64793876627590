import * as React from "react";
import slugify from "slugify";
import { classNames as c } from "@plinknz/tah-website-elements";

interface CommonDivProps extends React.HTMLProps<HTMLDivElement> {}

function Row({ children, className, ...rest }: CommonDivProps) {
    return (
        <div className={c("form-row", className)} {...rest}>
            {children}
        </div>
    );
}

interface GroupProps extends CommonDivProps {
    name?: string;
    label?: string;
}

const InputGroup = ({
    children,
    name,
    label,
    className,
    ...rest
}: GroupProps) => (
    <div className={c("form-group", className)} {...rest}>
        {label && (
            <label
                className="form-group-label"
                htmlFor={name || slugify(label)}>
                {label}
            </label>
        )}
        {children}
    </div>
);

interface FormProps extends React.HTMLProps<HTMLFormElement> {}
const FormComponent = ({
    children,
    className,
    onSubmit,
    ...rest
}: FormProps) => (
    <form
        className={c("form", className)}
        onSubmit={(event) => {
            event.preventDefault();
            if (onSubmit) {
                onSubmit(event);
            }
        }}
        {...rest}>
        {children}
    </form>
);

interface NoteProps extends React.HTMLProps<HTMLParagraphElement> {}
const Note = ({ children, className, ...rest }: NoteProps) => (
    <p className={c("form-note", className)} {...rest}>
        {children}
    </p>
);

const Actions = ({ children, className, ...rest }: CommonDivProps) => (
    <div className={c("form-actions", className)} {...rest}>
        {children}
    </div>
);

interface SectionProps extends CommonDivProps {
    heading: string;
}
const Section = ({ children, heading, ...rest }: SectionProps) => (
    <section className="form-section" {...rest}>
        <h4 className="heading-4">{heading}</h4>
        <hr className="breakline" />
        {children}
    </section>
);

export const Form = Object.assign(FormComponent, {
    Actions,
    Row,
    InputGroup,
    Note,
    Section,
});
